<template>
  <div class="w-full">
    <div class="relative">
      <div
        class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center z-30"
      >
        <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <vue-select
        v-model="search"
        :options="data ? extensionListConcat : quickData"
        group-by="isConstructor"
        label-by="label"
        value-by="value"
        :close-on-select="closeOnSelect ? true : false"
        searchable
        :loading="isLoading"
        clear-on-select
        :multiple="isMultiple ? true : false"
        :placeholder="selectedCustomer ? selectedCustomer : placeholder"
        @search:input="handleSearchInput"
        :maxHeight="maxHeight"
        class="text-left block w-1/2 bg-white border border-gray-300 rounded-md py-1 pl-10 pr-3 text-sm placeholder-gray-500 focus:text-gray-900 focus:placeholder-gray-400 encom_primary_border_focus sm:text-sm z-20"
      >
        <template #dropdown-item="{ option }">
          <template v-if="option.isConstructor">
            <div class="border-b border-gray-400 flex justify-center">
              {{ option.label }}
            </div>
          </template>
          <template v-else>
            <div class="inline-flex">
              <!-- <div class="pl-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div> -->
              <div class="pl-1">
                {{ option.label }}
              </div>
            </div>
          </template>
        </template>
      </vue-select>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import VueSelect from "vue-next-select";
import "vue-next-select/dist/index.css";

import { SearchIcon } from "@heroicons/vue/outline";

export default {
  props: [
    "placeholder",
    "selectedCustomer",
    "searchMethod",
    "data",
    "closeOnSelect",
    "isMultiple",
    "selectAllElements",
    "selectedAllElements",
    "clearAllElements",
    "elementToRemove",
    "maxHeight",
    "groupData",
    "extensionListConcat",
  ],
  components: {
    VueSelect,
    SearchIcon,
  },
  data() {
    return {
      quickData: [],
      search: "",
      searchableTerm: "",
      isLoading: false,
      isAllSelected: true,
      concatenatedData: [],
    };
  },
  methods: {
    handleSearchInput(event) {
      this.searchableTerm = event.target.value;
    },
    async getQuick(term) {
      try {
        if (term) {
          this.isLoading = true;

          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/getQuick?term=${term}`
          );
          console.log(res.data);
          this.quickData = res.data;
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        this.errorHandling(error);
      }
    },
  },
  watch: {
    searchableTerm: function(val) {
      if (this.searchMethod) {
        if (val.length > 2) {
          this.searchMethod(val);
        }
      }
    },
    search: function(val) {
      console.log(val);
      this.isLoading = false;
      this.$store.dispatch("pbxElements", val);
      this.$emit("action", val);
    },
    selectAllElements: function(val) {
      let selectAllArray = [];
      let selectAllArray2 = [];
      console.log(this.extensionListConcat);
      selectAllArray = this.extensionListConcat.filter((el) => {
        return el.isConstructor;
      });
      for (let index = 0; index < selectAllArray.length; index++) {
        const element = selectAllArray[index].value;
        for (let index2 = 0; index2 < element.length; index2++) {
          selectAllArray2.push(element[index2]);
        }
      }
      console.log(selectAllArray2);
      this.search = selectAllArray2;
      this.$emit("all-selected", true);
    },
    selectedAllElements: function(val) {
      if (val) {
        console.log("its true");

        let selectAllArray = [];
        let selectAllArray2 = [];
        console.log(this.extensionListConcat);
        selectAllArray = this.extensionListConcat.filter((el) => {
          return el.isConstructor;
        });
        for (let index = 0; index < selectAllArray.length; index++) {
          const element = selectAllArray[index].value;
          for (let index2 = 0; index2 < element.length; index2++) {
            selectAllArray2.push(element[index2]);
          }
        }
        console.log(selectAllArray2);
        this.search = selectAllArray2;
        this.$emit("all-selected", true);
      } else {
        console.log("its false");
        this.search = "";
        this.$emit("all-selected", false);
      }
    },
    clearAllElements: function(val) {
      this.search = "";
      this.$emit("all-selected", false);
    },
    elementToRemove: {
      handler(val) {
        let splitVal = val.split(" - ");
        const filteredSearch = this.search.filter(
          (element) => element != splitVal[0]
        );
        this.search = filteredSearch;
      },
      deep: true,
    },
  },
};
</script>

<style></style>
